.brand__lockup img {
  height: 84px;
}

.title-bar {
  font-family: $wcm-regular;
  padding-top: 5px;

  @include breakpoint($xs) {
    font-size: 28px;
  }
}

iframe {
  width: 100%;
}

.block-menu-block {
  @include breakpoint($md) {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em;
  }
}

.project-intake-form {
  &:after {
    content: '\e811';
    padding-left: 5px;
    @include fontello();
  }
}

div.horizontal-tabs {
  clear: both;
}

div.caption-left {
  @include breakpoint($md) {
    margin-top: 0;
  }
}

.arrow-icon-right {
  /*width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 20px;
    border-color: transparent transparent transparent #007bff;*/
  // width: 30px;
  height: 80%;
  margin-top: 5px;
  right: 40px;

  @include breakpoint($sm) {
    right: 60px;
  }

  @include v-center();
  position: absolute;

  &:after {
    content: '';
    position: absolute;
    transform: rotate(25deg);
    border-right: 4px solid $wcm-yellow;
    // width: 30px;
    height: 50%;
    bottom: 6%;
  }

  &:before {
    content: '';
    position: absolute;
    transform: rotate(335deg);
    border-right: 4px solid $wcm-yellow;
    // width: 30px;
    height: 50%;
    top: 0;
  }
}

.arrow-icon {
  position: absolute;
  top: 25%;
  right: 60px;
  // height: 20px;
  height: 50%;
  @include transition(all 0.25s);
  -moz-transition: all 0.25s;
  background-color: $wcm-yellow;
  display: inline-block;
  zoom: 1;
  margin-left: 10px;
  padding: 10px 5px 0px 0px;
  color: $wcm-yellow;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 5px solid $wcm-yellow;
    border-bottom: 10px solid transparent;
    margin: -10px 0px 0 5px;
  }

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    border-top: 10px solid transparent;
    border-left: 5px solid $wcm-white;
    border-bottom: 10px solid transparent;
    margin: -10px 0px 0 0px;
  }
}

.front {

  .container-top {
    .pane-bundle-text {
      h1 {
        text-align: center;
        font-size: 20px;
        padding: 15px;
        line-height: 1.2;

        @include breakpoint($sm) {
          padding: 15px 50px;
          font-size: 30px;
        }

        @include breakpoint($md) {
          padding: 15px 100px;
        }
      }
    }
  }

  .middle-column {
    background: url(../images/home_callout_bg.jpg);
    background-size: cover;
    padding-top: 60px;
    padding-bottom: 45px;

    .doormat-link {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .doormat-entry {
      position: relative;
      background: $wcm-white;

      // display: block;
      @include breakpoint($sm) {
        background: rgba(255, 255, 255, 0.9);
        @include transition(all 0.25s 0s);
        -moz-transition: all 0.25s 0s;
      }

      padding: 25px 45px 10px 45px;

      @include breakpoint((max: 515px)) {
        padding: 25px 45px 10px 25px;

        .arrow-icon-right {
          right: 30px;
        }
      }

      margin-bottom: 15px;

      .doormat-entry__description {
        padding-right: 5%;

        a {
          p {
            color: initial;
          }
        }

        p {
          &:last-child {
            margin-bottom: 5px;
          }

          a {
            @include breakpoint($sm) {
              visibility: hidden;
            }

            // overflow: hidden;
            // max-height: 0;
            // @include transition(all 0.25s 0s);
            // -moz-transition: all 0.25s 0s;
            position: relative;
            z-index: 2;
            display: inline-block;
          }
        }
      }

      h3 {
        margin-top: 0;
        color: $wcm-bright-orange;
        font-family: $wcm-regular;
        font-size: 40px;
      }

      @include breakpoint($sm) {
        .arrow-icon-right {
          display: none;
        }
      }

      &:hover {
        background: rgba(255, 255, 255, 1);

        .arrow-icon-right {
          display: block;
        }

        p a {
          visibility: visible;
          // max-height: 100px;
          // display: block;
        }
      }
    }
  }
}

.infographic {
  margin: 20px 0;

  .container {
    @include breakpoint($sm) {
      display: table;
      width: 100%;
    }

    .col-sm-5,
    .col-sm-7 {
      @include breakpoint($sm) {
        display: table-cell;
        vertical-align: middle;
        float: none;
        height: 100%
      }
    }
  }

  #map-canvas {
    position: relative;
    width: 100%;
    // height: 300px;
    height: 100%;
    padding-bottom: 100%;

    /*position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-left: 20px;
        margin-right: 20px;*/
    // display: inline-block;
  }

  .gm-style-cc {
    display: none;
  }

  .infographic-arrow {
    width: 120px;
    height: 50px;
    position: relative;
    border-bottom: 2px solid $wcm-border-gray;
    margin: 0 auto;
    cursor: pointer;

    &:after {
      content: "";
      border-color: $wcm-border-gray transparent transparent transparent;
      border-style: solid;
      border-width: 12px;
      width: 0;
      height: 0;
      position: absolute;
      // bottom:-24px;
      top: 100%;
      left: 48px;
      // left:45%;
    }
  }

  .infographic-stats {

    @include breakpoint($md) {
      display: flex;
      justify-content: center;
    }

    .infographic-container {
      border-radius: 50%;
      box-shadow: 0px 0px 0px 2px rgb(231, 119, 35), 0px 0px 0px 4px white, 0px 0px 0px 6px rgb(206, 69, 32);
      width: 200px;
      height: 200px;

      @include breakpoint($md) {
        width: 160px;
        height: 160px;
      }

      @include breakpoint($lg) {
        width: 200px;
        height: 200px;
        margin: 1em;
      }

      margin: 1em auto;

      // @include breakpoint($md) {
      //   margin: 1em;
      // }

      display: table;
    }

    .info {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }

    .col-xs-6 {
      text-align: center;
      border: 1px solid $wcm-border-gray;
      padding: 10px;

      &.hovered {
        z-index: 1000;
        text-decoration: none;
        @include scale(1.05);
        border-width: 0px;
        background: #fff;

        &:after {
          opacity: 1;
        }
      }

      &.map-buildings-owned,
      &.map-spaces-leased,
      &.map-network-practices {
        cursor: pointer;
      }

      /*.infographic-container {
                -webkit-animation: rotatenumber 1.5s 1s ease-out backwards;
                -moz-animation:    rotatenumber 1.5s 1s ease-out backwards;
                -o-animation:      rotatenumber 1.5s 1s ease-out backwards;
                animation:         rotatenumber 1.5s 1s ease-out backwards;
            }*/

      .info-number {
        font-family: $wcm-bold;
        font-size: 50px;
        height: 70px;

        @include breakpoint($md) {
          font-size: 35px;
          height: 50px;
        }

        @include breakpoint($lg) {
          font-size: 50px;
          height: 70px;
        }
      }

      .info-title {
        font-family: $wcm-regular;
        color: $wcm-med-gray;
        font-size: 20px;
      }

      &:nth-child(1) {
        border-width: 0 0 0 0;

        .info-number {
          color: $wcm-bright-orange;
        }
      }

      &:nth-child(2) {
        border-width: 0 0 0 0;

        .info-number {
          color: $wcm-dark-orange;
        }
      }

      &:nth-child(3) {
        border-width: 0 0 0 0;

        .info-number {
          color: $wcm-yellow;
        }
      }

      /*&:nth-child(4) {
                border-width: 1px 0 0 0;
                .info-number {
                    color: $wcm-red;
                }
            }*/

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
        opacity: 0;
      }

      &:hover {
        z-index: 1000;
        text-decoration: none;
        @include scale(1.05);
        // -webkit-transform: scale(1.1, 1.1);
        // -ms-transform: scale(1.1, 1.1);
        // transform: scale(1.1, 1.1);
        border-width: 0px;
        background: #fff;

        &:after {
          opacity: 1;
        }

        &:last-child {
          @include scale(1);
          border-width: 0 0 0 0;

          &:after {
            opacity: 0;
          }
        }
      }
    }
  }
}

.node-type-highlighted-projects {

  .main-content {

    /*display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-flow: row wrap;*/

    // .pane-entity-field.col-md-6 {
    .pane-entity-field.col-sm-6 {

      h3.pane-title {
        text-transform: uppercase;
        font-size: 12px;
        color: $wcm-med-gray;
        letter-spacing: 2px;
      }

      @include breakpoint($xs) {
        &:nth-child(even) {
          padding-left: 0;
        }
      }

      @include breakpoint($sm) {
        &:nth-child(even) {
          padding-right: 0;
          padding-left: 0;
        }

        /*&:nth-child(odd) {
                    // padding-left: 0;
                }*/
      }

      @include breakpoint($md) {
        &:nth-child(even) {
          padding-right: 0;
          padding-left: inherit;
        }
      }

      // border-bottom: 1px solid $wcm-border-gray;
      /*&:after {
                content: '';
                border-bottom: 1px solid $wcm-border-gray;
                display: block;
            }*/
      div {
        // border-bottom: 1px solid $wcm-border-gray;
        padding-bottom: 15px;
      }

      &:nth-child(odd) {
        clear: both;
        padding-left: 0;
      }
    }

    .pane-node-field-project-location-address {
      margin-top: 20px;
    }

    .pane-node-body {
      // @include clearfix();
      border-top: 1px solid $wcm-border-gray;
      // padding-top: 20px;
      margin-top: 30px;
      clear: both;
      display: inline-block;
      width: 100%;

      h3.pane-title {
        margin-top: 30px;
      }
    }
  }

  .gallery-image-fullscreen {
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 20px;
    z-index: 1005;
    cursor: pointer;

    @include breakpoint($sm) {
      height: 30px;
      bottom: 15px;
      right: 15px;
    }

    .cta-icon {
      width: 20px;
      height: 20px;

      @include breakpoint($sm) {
        width: 30px;
        height: 30px;
      }

      fill: $wcm-white;
      color: $wcm-white;
    }
  }

  /*.pane-node-body {
        padding-top: 20px;
        clear: both;
    }*/
}

.address-locations-buildings-owned,
.address-locations-spaces-leased,
.address-locations-network-practices {
  display: none;
}

.view-profiles {
  @include clearfix();

  .views-row {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px;

    .views-field-field-staff-tags {
      font-size: 14px;
      color: $wcm-med-gray;
    }

    &:nth-child(odd) {
      clear: both;

      @include breakpoint($sm) {
        padding-right: 10px;
      }
    }

    &:nth-child(even) {
      @include breakpoint($sm) {
        padding-left: 10px;
      }
    }
  }

  .pane-profiles-panel-pane-4 &,
  .pane-profiles-panel-pane-5 &,
  .pane-profiles-panel-pane-6 & {
    .views-row {
      @include clearfix();
      padding-left: 0;

      h4 {
        margin-top: 0;
      }

      .views-field-field-profile-image {
        float: left;
        margin-right: 20px;
      }
    }
  }
}

@-webkit-keyframes rotatenumber {
  from {
    @include scale(1.0);
    // transform: rotate(0deg);
  }

  to {
    @include scale(1.05);
    // transform: rotate(360deg);
  }

  /*0% { transform: rotate(25deg); }
    0% { transform: rotate(25deg); }
    0% { transform: rotate(25deg); }
    0% { transform: rotate(25deg); }*/
}

@-webkit-keyframes trianglepulse {
  0% {
    @include scale(1.0);
  }

  100% {
    @include scale(1.2);
  }
}

@-moz-keyframes trianglepulse {
  0% {
    @include scale(1.0);
  }

  100% {
    @include scale(1.2);
  }
}

@-o-keyframes trianglepulse {
  0% {
    @include scale(1.0);
  }

  100% {
    @include scale(1.2);
  }
}

@keyframes trianglepulse {
  0% {
    @include scale(1.0);
  }

  100% {
    @include scale(1.2);
  }
}

.view-highlighted-projects {
  @include clearfix();
  margin-top: 40px;

  /*.views-field-field-project-featured-image {
        float: left;
        width: 33.3%;
    }*/

  .view-content {
    @include breakpoint($sm) {
      margin: 0 -10px;
    }
  }

  .views-row,
  .col-sm-4 {
    position: relative;

    cursor: pointer;
    padding: 0 10px;
    margin-bottom: 20px;

    /*&:nth-child(3n+1) {
            padding-right: 10px;
        }
        &:nth-child(3n) {
            padding-left: 10px;
        }
        &:nth-child(3n+2) {
            padding-left: 5px;
            padding-right: 5px;
        }*/

    .project-thumb {
      position: relative;

      img {
        width: 100%
      }

      &:hover {
        .project-title {
          background: none;
          // background: rgba(0,0,0, 0.6);
        }

        .project-title-overlay {
          background: $wcm-bright-orange;
          mix-blend-mode: multiply;
        }
      }

      .project-title-overlay {
        width: 80%;
        height: 80%;
        @include center();
      }

      &.active {
        .project-title-overlay {
          background: $wcm-bright-orange;
          mix-blend-mode: multiply;

          &.opacity-mode {
            mix-blend-mode: normal;
            opacity: 0.7;
          }
        }

        .project-title {
          background: none;
          // background: rgba(232, 119, 34, 0.7);
          // mix-blend-mode: multiply;
        }

        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 100%;
          margin-left: -20px;
          width: 0px;
          height: 0px;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid $wcm-dark-gray;
        }
      }
    }

    .views-field-title,
    .project-title {
      width: 80%;
      height: 80%;
      border: 2px solid $wcm-white;
      @include center();
      background: rgba(0, 0, 0, 0.4);
      margin-top: 0;

      h2 {
        @include center();
        margin-top: 0;
        width: 100%;
        padding: 0 20px;

        color: $wcm-white;
        font-size: 24px;

        &:after {
          display: none;
        }
      }
    }

    .project-info {
      display: none;
    }

    &.col-sm-4 {

      // @include breakpoint($xs) {
      @include breakpoint((max: 768px)) {
        width: 50%;
        float: left;

        &.views-row-odd {
          padding-left: 0;
          padding-right: 10px;
        }

        &.views-row-even {
          padding-left: 10px;
          padding-right: 0;
        }
      }

      @include breakpoint((max: 515px)) {
        width: 100%;
        float: none;

        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .project-info-box,
  .project-info-box-medium,
  .project-info-box-small {
    @include clearfix();
    background: $wcm-bg-gray;
    clear: both;
    margin: 0 10px 20px;
    display: none;
    position: relative;

    .project-info-text {
      position: relative;
      padding: 20px 20px 10px;

      @include breakpoint($sm) {
        float: left;
        width: 66.6666666667%;
      }
    }

    .project-info-btn {
      position: relative;

      @include breakpoint($sm) {
        position: absolute;
        right: 0;
        height: 100%;
        // float: left;
        width: 33.3333333333%;
        padding-left: 20px;
      }

      .project-info-btn-container {
        border: 2px solid $wcm-yellow;
        position: relative;
        height: 100%;
      }
    }

    .view-project-btn {
      padding: 20px 10px;

      @include breakpoint($sm) {
        @include v-center();
        padding: 25px 10px;
      }

      position: relative;
      width: 100%;
      display: inline-block;
      text-align: center;

      &:before {
        content: '';
        position: relative;
        background: url(../images/icon-view-project-colored.png) no-repeat;
        background-size: 40px 23px;
        width: 40px;
        height: 23px;
        display: block;
        margin: 0 auto 15px auto;
      }
    }
  }

  // homepage
  .pane-highlighted-projects-panel-pane-2 & {

    @include breakpoint($sm) {
      margin-left: -20px;
      margin-right: -20px;
    }

    @include breakpoint((max: 768px)) {
      margin-left: 0px;
      margin-right: 0px;
    }

    .views-row:nth-child(4) {
      @include breakpoint((min: 769px)) {
        clear: both;
      }
    }

    .views-row {

      margin-bottom: 25px;
      @include transition(all 0.25s);
      -moz-transition: all 0.25s;

      .view-project-label {
        @include transition(all 0.25s);
        -moz-transition: all 0.25s;
        position: absolute;
        top: 5px;
        right: 10px;
        opacity: 1;

        // @include breakpoint($sm) {
        @include breakpoint((min: 769px)) {
          right: 30px;
          opacity: 0;
        }

        font-size: 12px;
        color: $wcm-white;

        &:before {
          content: '';
          background: url(../images/icon-view-project.png) no-repeat;
          width: 25px;
          height: 14px;
          left: -30px;
          top: 3px;
          position: absolute;
          background-size: 100%;
        }
      }

      .views-field-field-project-featured-image {
        background: $wcm-bright-orange;

        // @include breakpoint($sm) {
        @include breakpoint((min: 769px)) {
          background: none;
        }

        @include transition(all 0.25s);
        -moz-transition: all 0.25s;

        img {
          width: 100%;
          mix-blend-mode: multiply;

          @include breakpoint($xs) {
            &.opacity-mode {
              mix-blend-mode: normal;
              opacity: 0.3;
            }
          }
        }
      }

      .views-field-title {
        @include transition(all 0.25s);
        -moz-transition: all 0.25s;

        // @include breakpoint($sm) {
        @include breakpoint((min: 769px)) {
          opacity: 0;
        }

        background: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        -webkit-transform: inherit;
        transform: inherit;
        border: none;
        color: $wcm-white;
        font-family: $wcm-bold;
        // text-align: center;
        z-index: 10;

        @include breakpoint($xs) {
          font-size: 30px;
          line-height: 40px;
        }

        a {
          text-decoration: none;
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;

          h2 {
            @include center();
            width: auto;

            @include breakpoint((max: 515px)) {
              width: 80%;
            }

            padding: 30px 30px 20px;
            font-size: 30px;
            line-height: 30px;
            border: 2px solid $wcm-white;
          }
        }
      }

      &:hover {

        .views-field-title,
        .view-project-label {
          opacity: 1;
        }

        .views-field-field-project-featured-image {
          background: $wcm-bright-orange;

          img.opacity-mode {
            opacity: 0.3;
          }
        }

        &:after {
          opacity: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 5px;
        right: 25px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 25px 25px;
        border-color: transparent transparent #fff transparent;

        // @include breakpoint($xs) {
        @include breakpoint((max: 768px)) {
          opacity: 0;
        }

        -webkit-animation: trianglepulse 1s ease-out infinite alternate;
        /* Safari 4+ */
        -moz-animation: trianglepulse 1s ease-out infinite alternate;
        /* Fx 5+ */
        -o-animation: trianglepulse 1s ease-out infinite alternate;
        /* Opera 12+ */
        animation: trianglepulse 1s ease-out infinite alternate;
        /* IE 10+, Fx 29+ */
      }

      padding-left: 20px;
      padding-right: 20px;

      @include breakpoint($xs) {
        width: auto;
        float: none;
        padding: inherit !important;
      }

      &:first-child {
        @include breakpoint($sm) {
          width: 66.6666666667%;
        }

        @include breakpoint((max: 768px)) {
          width: 50%;
        }

        @include breakpoint($xs) {
          width: auto;
        }

        a h2 {
          font-size: 30px;
          line-height: 30px;

          // @include breakpoint($sm) {
          @include breakpoint((min: 769px)) {
            font-size: 50px;
            line-height: 50px;
          }
        }
      }
    }
  }
}

.wcm-callout {
  position: relative;

  margin-top: 30px;
  margin-bottom: 30px;

  .wcm-callout-title {
    // width: 80%;
    // height: 80%;
    padding: 30px 30px 20px;
    font-size: 50px;
    line-height: 50px;
    border: 2px solid $wcm-white;
    @include center();
    // background: rgba(0,0,0, 0.3);
    color: $wcm-white;
    font-family: $wcm-bold;
    text-align: center;
    z-index: 10;

    @include breakpoint($xs) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  img {
    mix-blend-mode: multiply; // soft-light;
    margin-bottom: 0 !important;

    &.opacity-mode {
      opacity: 0.3;
    }
  }

  &.wcm-red {
    background: $wcm-red;

    &:hover {
      background: $wcm-bright-orange;
    }
  }

  &.wcm-dark-orange {
    background: $wcm-dark-orange;

    &:hover {
      background: $wcm-bright-orange;
    }
  }

  &.wcm-bright-orange {
    background: $wcm-bright-orange;

    &:hover {
      background: $wcm-yellow;
    }
  }

  &.wcm-yellow {
    background: $wcm-yellow;

    &:hover {
      background: $wcm-bright-orange;
    }
  }

  &.no-image {
    padding: 100px;
  }

}

.panel-onecol {
  .pane-wcm-cta {
    .wcm-cta {
      width: auto;
      margin-top: 30px;

      .wcm-cta__tile {
        width: 25%;

        @include breakpoint($xs) {
          width: 50%;

          &:nth-child(even) {
            border-right: none;
          }

          &:nth-child(1),
          &:nth-child(2) {
            border-bottom: 1px solid $wcm-border-gray;
          }
        }

        border: none;
        border-right: 1px solid $wcm-border-gray;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}

#btn-vendor-inquiries,
#btn-commercial-inquiries,
#btn-project-intake {
  -webkit-box-shadow: none;
  box-shadow: none;

  &.active {
    background: $wcm-med-gray;
    border-color: $wcm-med-gray;
  }
}

.node-webform {
  display: none;
  margin-top: 20px;

  h2 {
    display: none;
  }

  .form-control {
    max-width: none;
  }

  .select2-container {
    width: 220px !important;
  }
}
