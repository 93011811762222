/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
.brand__lockup img {
  height: 84px; }

.title-bar {
  font-family: "1898Sans-Regular", sans-serif;
  padding-top: 5px; }
  @media screen and (max-width: 767px) {
    .title-bar {
      font-size: 28px; } }

iframe {
  width: 100%; }

@media screen and (min-width: 992px) {
  .block-menu-block {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    -ms-column-count: 3;
    -o-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    -ms-column-gap: 15px;
    -o-column-gap: 15px;
    column-gap: 15px;
    margin-bottom: 1em; } }

.project-intake-form:after {
  content: '\e811';
  padding-left: 5px;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

div.horizontal-tabs {
  clear: both; }

@media screen and (min-width: 992px) {
  div.caption-left {
    margin-top: 0; } }

.arrow-icon-right {
  /*width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px 0 40px 20px;
    border-color: transparent transparent transparent #007bff;*/
  height: 80%;
  margin-top: 5px;
  right: 40px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
  -ms-transform: translateY(-50%);
  /* IE 9 */
  transform: translateY(-50%);
  /* IE 10, Fx 16+, Op 12.1+ */
  position: absolute; }
  @media screen and (min-width: 768px) {
    .arrow-icon-right {
      right: 60px; } }
  .arrow-icon-right:after {
    content: '';
    position: absolute;
    transform: rotate(25deg);
    border-right: 4px solid #ffc72c;
    height: 50%;
    bottom: 6%; }
  .arrow-icon-right:before {
    content: '';
    position: absolute;
    transform: rotate(335deg);
    border-right: 4px solid #ffc72c;
    height: 50%;
    top: 0; }

.arrow-icon {
  position: absolute;
  top: 25%;
  right: 60px;
  height: 50%;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  -moz-transition: all 0.25s;
  background-color: #ffc72c;
  display: inline-block;
  zoom: 1;
  margin-left: 10px;
  padding: 10px 5px 0px 0px;
  color: #ffc72c; }
  .arrow-icon:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 5px solid #ffc72c;
    border-bottom: 10px solid transparent;
    margin: -10px 0px 0 5px; }
  .arrow-icon:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    border-top: 10px solid transparent;
    border-left: 5px solid #fff;
    border-bottom: 10px solid transparent;
    margin: -10px 0px 0 0px; }

.front .container-top .pane-bundle-text h1 {
  text-align: center;
  font-size: 20px;
  padding: 15px;
  line-height: 1.2; }
  @media screen and (min-width: 768px) {
    .front .container-top .pane-bundle-text h1 {
      padding: 15px 50px;
      font-size: 30px; } }
  @media screen and (min-width: 992px) {
    .front .container-top .pane-bundle-text h1 {
      padding: 15px 100px; } }

.front .middle-column {
  background: url(../images/home_callout_bg.jpg);
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 45px; }
  .front .middle-column .doormat-link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .front .middle-column .doormat-entry {
    position: relative;
    background: #fff;
    padding: 25px 45px 10px 45px;
    margin-bottom: 15px; }
    @media screen and (min-width: 768px) {
      .front .middle-column .doormat-entry {
        background: rgba(255, 255, 255, 0.9);
        -webkit-transition: all 0.25s 0s;
        -o-transition: all 0.25s 0s;
        transition: all 0.25s 0s;
        -moz-transition: all 0.25s 0s; } }
    @media screen and (max-width: 515px) {
      .front .middle-column .doormat-entry {
        padding: 25px 45px 10px 25px; }
        .front .middle-column .doormat-entry .arrow-icon-right {
          right: 30px; } }
    .front .middle-column .doormat-entry .doormat-entry__description {
      padding-right: 5%; }
      .front .middle-column .doormat-entry .doormat-entry__description a p {
        color: initial; }
      .front .middle-column .doormat-entry .doormat-entry__description p:last-child {
        margin-bottom: 5px; }
      .front .middle-column .doormat-entry .doormat-entry__description p a {
        position: relative;
        z-index: 2;
        display: inline-block; }
        @media screen and (min-width: 768px) {
          .front .middle-column .doormat-entry .doormat-entry__description p a {
            visibility: hidden; } }
    .front .middle-column .doormat-entry h3 {
      margin-top: 0;
      color: #e7751d;
      font-family: "1898Sans-Regular", sans-serif;
      font-size: 40px; }
    @media screen and (min-width: 768px) {
      .front .middle-column .doormat-entry .arrow-icon-right {
        display: none; } }
    .front .middle-column .doormat-entry:hover {
      background: white; }
      .front .middle-column .doormat-entry:hover .arrow-icon-right {
        display: block; }
      .front .middle-column .doormat-entry:hover p a {
        visibility: visible; }

.infographic {
  margin: 20px 0; }
  @media screen and (min-width: 768px) {
    .infographic .container {
      display: table;
      width: 100%; } }
  @media screen and (min-width: 768px) {
    .infographic .container .col-sm-5,
    .infographic .container .col-sm-7 {
      display: table-cell;
      vertical-align: middle;
      float: none;
      height: 100%; } }
  .infographic #map-canvas {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 100%;
    /*position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin-left: 20px;
        margin-right: 20px;*/ }
  .infographic .gm-style-cc {
    display: none; }
  .infographic .infographic-arrow {
    width: 120px;
    height: 50px;
    position: relative;
    border-bottom: 2px solid #dddddd;
    margin: 0 auto;
    cursor: pointer; }
    .infographic .infographic-arrow:after {
      content: "";
      border-color: #dddddd transparent transparent transparent;
      border-style: solid;
      border-width: 12px;
      width: 0;
      height: 0;
      position: absolute;
      top: 100%;
      left: 48px; }
  @media screen and (min-width: 992px) {
    .infographic .infographic-stats {
      display: flex;
      justify-content: center; } }
  .infographic .infographic-stats .infographic-container {
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px #e77723, 0px 0px 0px 4px white, 0px 0px 0px 6px #ce4520;
    width: 200px;
    height: 200px;
    margin: 1em auto;
    display: table; }
    @media screen and (min-width: 992px) {
      .infographic .infographic-stats .infographic-container {
        width: 160px;
        height: 160px; } }
    @media screen and (min-width: 1200px) {
      .infographic .infographic-stats .infographic-container {
        width: 200px;
        height: 200px;
        margin: 1em; } }
  .infographic .infographic-stats .info {
    display: table-cell;
    text-align: center;
    vertical-align: middle; }
  .infographic .infographic-stats .col-xs-6 {
    text-align: center;
    border: 1px solid #dddddd;
    padding: 10px;
    /*.infographic-container {
                -webkit-animation: rotatenumber 1.5s 1s ease-out backwards;
                -moz-animation:    rotatenumber 1.5s 1s ease-out backwards;
                -o-animation:      rotatenumber 1.5s 1s ease-out backwards;
                animation:         rotatenumber 1.5s 1s ease-out backwards;
            }*/
    /*&:nth-child(4) {
                border-width: 1px 0 0 0;
                .info-number {
                    color: $wcm-red;
                }
            }*/ }
    .infographic .infographic-stats .col-xs-6.hovered {
      z-index: 1000;
      text-decoration: none;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
      border-width: 0px;
      background: #fff; }
      .infographic .infographic-stats .col-xs-6.hovered:after {
        opacity: 1; }
    .infographic .infographic-stats .col-xs-6.map-buildings-owned, .infographic .infographic-stats .col-xs-6.map-spaces-leased, .infographic .infographic-stats .col-xs-6.map-network-practices {
      cursor: pointer; }
    .infographic .infographic-stats .col-xs-6 .info-number {
      font-family: "1898Sans-Bold", sans-serif;
      font-size: 50px;
      height: 70px; }
      @media screen and (min-width: 992px) {
        .infographic .infographic-stats .col-xs-6 .info-number {
          font-size: 35px;
          height: 50px; } }
      @media screen and (min-width: 1200px) {
        .infographic .infographic-stats .col-xs-6 .info-number {
          font-size: 50px;
          height: 70px; } }
    .infographic .infographic-stats .col-xs-6 .info-title {
      font-family: "1898Sans-Regular", sans-serif;
      color: #666666;
      font-size: 20px; }
    .infographic .infographic-stats .col-xs-6:nth-child(1) {
      border-width: 0 0 0 0; }
      .infographic .infographic-stats .col-xs-6:nth-child(1) .info-number {
        color: #e7751d; }
    .infographic .infographic-stats .col-xs-6:nth-child(2) {
      border-width: 0 0 0 0; }
      .infographic .infographic-stats .col-xs-6:nth-child(2) .info-number {
        color: #cf4520; }
    .infographic .infographic-stats .col-xs-6:nth-child(3) {
      border-width: 0 0 0 0; }
      .infographic .infographic-stats .col-xs-6:nth-child(3) .info-number {
        color: #ffc72c; }
    .infographic .infographic-stats .col-xs-6:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
      opacity: 0; }
    .infographic .infographic-stats .col-xs-6:hover {
      z-index: 1000;
      text-decoration: none;
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      -o-transform: scale(1.05);
      transform: scale(1.05);
      border-width: 0px;
      background: #fff; }
      .infographic .infographic-stats .col-xs-6:hover:after {
        opacity: 1; }
      .infographic .infographic-stats .col-xs-6:hover:last-child {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        border-width: 0 0 0 0; }
        .infographic .infographic-stats .col-xs-6:hover:last-child:after {
          opacity: 0; }

.node-type-highlighted-projects {
  /*.pane-node-body {
        padding-top: 20px;
        clear: both;
    }*/ }
  .node-type-highlighted-projects .main-content {
    /*display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-flow: row wrap;*/ }
    .node-type-highlighted-projects .main-content .pane-entity-field.col-sm-6 {
      /*&:after {
                content: '';
                border-bottom: 1px solid $wcm-border-gray;
                display: block;
            }*/ }
      .node-type-highlighted-projects .main-content .pane-entity-field.col-sm-6 h3.pane-title {
        text-transform: uppercase;
        font-size: 12px;
        color: #666666;
        letter-spacing: 2px; }
      @media screen and (max-width: 767px) {
        .node-type-highlighted-projects .main-content .pane-entity-field.col-sm-6:nth-child(even) {
          padding-left: 0; } }
      @media screen and (min-width: 768px) {
        .node-type-highlighted-projects .main-content .pane-entity-field.col-sm-6 {
          /*&:nth-child(odd) {
                    // padding-left: 0;
                }*/ }
          .node-type-highlighted-projects .main-content .pane-entity-field.col-sm-6:nth-child(even) {
            padding-right: 0;
            padding-left: 0; } }
      @media screen and (min-width: 992px) {
        .node-type-highlighted-projects .main-content .pane-entity-field.col-sm-6:nth-child(even) {
          padding-right: 0;
          padding-left: inherit; } }
      .node-type-highlighted-projects .main-content .pane-entity-field.col-sm-6 div {
        padding-bottom: 15px; }
      .node-type-highlighted-projects .main-content .pane-entity-field.col-sm-6:nth-child(odd) {
        clear: both;
        padding-left: 0; }
    .node-type-highlighted-projects .main-content .pane-node-field-project-location-address {
      margin-top: 20px; }
    .node-type-highlighted-projects .main-content .pane-node-body {
      border-top: 1px solid #dddddd;
      margin-top: 30px;
      clear: both;
      display: inline-block;
      width: 100%; }
      .node-type-highlighted-projects .main-content .pane-node-body h3.pane-title {
        margin-top: 30px; }
  .node-type-highlighted-projects .gallery-image-fullscreen {
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 20px;
    z-index: 1005;
    cursor: pointer; }
    @media screen and (min-width: 768px) {
      .node-type-highlighted-projects .gallery-image-fullscreen {
        height: 30px;
        bottom: 15px;
        right: 15px; } }
    .node-type-highlighted-projects .gallery-image-fullscreen .cta-icon {
      width: 20px;
      height: 20px;
      fill: #fff;
      color: #fff; }
      @media screen and (min-width: 768px) {
        .node-type-highlighted-projects .gallery-image-fullscreen .cta-icon {
          width: 30px;
          height: 30px; } }

.address-locations-buildings-owned,
.address-locations-spaces-leased,
.address-locations-network-practices {
  display: none; }

.view-profiles:before, .view-profiles:after {
  content: " ";
  display: table; }

.view-profiles:after {
  clear: both; }

.view-profiles .views-row {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 30px; }
  .view-profiles .views-row .views-field-field-staff-tags {
    font-size: 14px;
    color: #666666; }
  .view-profiles .views-row:nth-child(odd) {
    clear: both; }
    @media screen and (min-width: 768px) {
      .view-profiles .views-row:nth-child(odd) {
        padding-right: 10px; } }
  @media screen and (min-width: 768px) {
    .view-profiles .views-row:nth-child(even) {
      padding-left: 10px; } }

.pane-profiles-panel-pane-4 .view-profiles .views-row,
.pane-profiles-panel-pane-5 .view-profiles .views-row,
.pane-profiles-panel-pane-6 .view-profiles .views-row {
  padding-left: 0; }
  .pane-profiles-panel-pane-4 .view-profiles .views-row:before, .pane-profiles-panel-pane-4 .view-profiles .views-row:after,
  .pane-profiles-panel-pane-5 .view-profiles .views-row:before,
  .pane-profiles-panel-pane-5 .view-profiles .views-row:after,
  .pane-profiles-panel-pane-6 .view-profiles .views-row:before,
  .pane-profiles-panel-pane-6 .view-profiles .views-row:after {
    content: " ";
    display: table; }
  .pane-profiles-panel-pane-4 .view-profiles .views-row:after,
  .pane-profiles-panel-pane-5 .view-profiles .views-row:after,
  .pane-profiles-panel-pane-6 .view-profiles .views-row:after {
    clear: both; }
  .pane-profiles-panel-pane-4 .view-profiles .views-row h4,
  .pane-profiles-panel-pane-5 .view-profiles .views-row h4,
  .pane-profiles-panel-pane-6 .view-profiles .views-row h4 {
    margin-top: 0; }
  .pane-profiles-panel-pane-4 .view-profiles .views-row .views-field-field-profile-image,
  .pane-profiles-panel-pane-5 .view-profiles .views-row .views-field-field-profile-image,
  .pane-profiles-panel-pane-6 .view-profiles .views-row .views-field-field-profile-image {
    float: left;
    margin-right: 20px; }

@-webkit-keyframes rotatenumber {
  from {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  to {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  /*0% { transform: rotate(25deg); }
    0% { transform: rotate(25deg); }
    0% { transform: rotate(25deg); }
    0% { transform: rotate(25deg); }*/ }

@-webkit-keyframes trianglepulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); } }

@-moz-keyframes trianglepulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); } }

@-o-keyframes trianglepulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); } }

@keyframes trianglepulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); } }

.view-highlighted-projects {
  margin-top: 40px;
  /*.views-field-field-project-featured-image {
        float: left;
        width: 33.3%;
    }*/ }
  .view-highlighted-projects:before, .view-highlighted-projects:after {
    content: " ";
    display: table; }
  .view-highlighted-projects:after {
    clear: both; }
  @media screen and (min-width: 768px) {
    .view-highlighted-projects .view-content {
      margin: 0 -10px; } }
  .view-highlighted-projects .views-row,
  .view-highlighted-projects .col-sm-4 {
    position: relative;
    cursor: pointer;
    padding: 0 10px;
    margin-bottom: 20px;
    /*&:nth-child(3n+1) {
            padding-right: 10px;
        }
        &:nth-child(3n) {
            padding-left: 10px;
        }
        &:nth-child(3n+2) {
            padding-left: 5px;
            padding-right: 5px;
        }*/ }
    .view-highlighted-projects .views-row .project-thumb,
    .view-highlighted-projects .col-sm-4 .project-thumb {
      position: relative; }
      .view-highlighted-projects .views-row .project-thumb img,
      .view-highlighted-projects .col-sm-4 .project-thumb img {
        width: 100%; }
      .view-highlighted-projects .views-row .project-thumb:hover .project-title,
      .view-highlighted-projects .col-sm-4 .project-thumb:hover .project-title {
        background: none; }
      .view-highlighted-projects .views-row .project-thumb:hover .project-title-overlay,
      .view-highlighted-projects .col-sm-4 .project-thumb:hover .project-title-overlay {
        background: #e7751d;
        mix-blend-mode: multiply; }
      .view-highlighted-projects .views-row .project-thumb .project-title-overlay,
      .view-highlighted-projects .col-sm-4 .project-thumb .project-title-overlay {
        width: 80%;
        height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); }
      .view-highlighted-projects .views-row .project-thumb.active .project-title-overlay,
      .view-highlighted-projects .col-sm-4 .project-thumb.active .project-title-overlay {
        background: #e7751d;
        mix-blend-mode: multiply; }
        .view-highlighted-projects .views-row .project-thumb.active .project-title-overlay.opacity-mode,
        .view-highlighted-projects .col-sm-4 .project-thumb.active .project-title-overlay.opacity-mode {
          mix-blend-mode: normal;
          opacity: 0.7; }
      .view-highlighted-projects .views-row .project-thumb.active .project-title,
      .view-highlighted-projects .col-sm-4 .project-thumb.active .project-title {
        background: none; }
      .view-highlighted-projects .views-row .project-thumb.active:after,
      .view-highlighted-projects .col-sm-4 .project-thumb.active:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        margin-left: -20px;
        width: 0px;
        height: 0px;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #555555; }
    .view-highlighted-projects .views-row .views-field-title,
    .view-highlighted-projects .views-row .project-title,
    .view-highlighted-projects .col-sm-4 .views-field-title,
    .view-highlighted-projects .col-sm-4 .project-title {
      width: 80%;
      height: 80%;
      border: 2px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.4);
      margin-top: 0; }
      .view-highlighted-projects .views-row .views-field-title h2,
      .view-highlighted-projects .views-row .project-title h2,
      .view-highlighted-projects .col-sm-4 .views-field-title h2,
      .view-highlighted-projects .col-sm-4 .project-title h2 {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin-top: 0;
        width: 100%;
        padding: 0 20px;
        color: #fff;
        font-size: 24px; }
        .view-highlighted-projects .views-row .views-field-title h2:after,
        .view-highlighted-projects .views-row .project-title h2:after,
        .view-highlighted-projects .col-sm-4 .views-field-title h2:after,
        .view-highlighted-projects .col-sm-4 .project-title h2:after {
          display: none; }
    .view-highlighted-projects .views-row .project-info,
    .view-highlighted-projects .col-sm-4 .project-info {
      display: none; }
    @media screen and (max-width: 768px) {
      .view-highlighted-projects .views-row.col-sm-4,
      .view-highlighted-projects .col-sm-4.col-sm-4 {
        width: 50%;
        float: left; }
        .view-highlighted-projects .views-row.col-sm-4.views-row-odd,
        .view-highlighted-projects .col-sm-4.col-sm-4.views-row-odd {
          padding-left: 0;
          padding-right: 10px; }
        .view-highlighted-projects .views-row.col-sm-4.views-row-even,
        .view-highlighted-projects .col-sm-4.col-sm-4.views-row-even {
          padding-left: 10px;
          padding-right: 0; } }
    @media screen and (max-width: 515px) {
      .view-highlighted-projects .views-row.col-sm-4,
      .view-highlighted-projects .col-sm-4.col-sm-4 {
        width: 100%;
        float: none;
        padding-left: 0 !important;
        padding-right: 0 !important; } }
  .view-highlighted-projects .project-info-box,
  .view-highlighted-projects .project-info-box-medium,
  .view-highlighted-projects .project-info-box-small {
    background: #f7f7f7;
    clear: both;
    margin: 0 10px 20px;
    display: none;
    position: relative; }
    .view-highlighted-projects .project-info-box:before, .view-highlighted-projects .project-info-box:after,
    .view-highlighted-projects .project-info-box-medium:before,
    .view-highlighted-projects .project-info-box-medium:after,
    .view-highlighted-projects .project-info-box-small:before,
    .view-highlighted-projects .project-info-box-small:after {
      content: " ";
      display: table; }
    .view-highlighted-projects .project-info-box:after,
    .view-highlighted-projects .project-info-box-medium:after,
    .view-highlighted-projects .project-info-box-small:after {
      clear: both; }
    .view-highlighted-projects .project-info-box .project-info-text,
    .view-highlighted-projects .project-info-box-medium .project-info-text,
    .view-highlighted-projects .project-info-box-small .project-info-text {
      position: relative;
      padding: 20px 20px 10px; }
      @media screen and (min-width: 768px) {
        .view-highlighted-projects .project-info-box .project-info-text,
        .view-highlighted-projects .project-info-box-medium .project-info-text,
        .view-highlighted-projects .project-info-box-small .project-info-text {
          float: left;
          width: 66.6666666667%; } }
    .view-highlighted-projects .project-info-box .project-info-btn,
    .view-highlighted-projects .project-info-box-medium .project-info-btn,
    .view-highlighted-projects .project-info-box-small .project-info-btn {
      position: relative; }
      @media screen and (min-width: 768px) {
        .view-highlighted-projects .project-info-box .project-info-btn,
        .view-highlighted-projects .project-info-box-medium .project-info-btn,
        .view-highlighted-projects .project-info-box-small .project-info-btn {
          position: absolute;
          right: 0;
          height: 100%;
          width: 33.3333333333%;
          padding-left: 20px; } }
      .view-highlighted-projects .project-info-box .project-info-btn .project-info-btn-container,
      .view-highlighted-projects .project-info-box-medium .project-info-btn .project-info-btn-container,
      .view-highlighted-projects .project-info-box-small .project-info-btn .project-info-btn-container {
        border: 2px solid #ffc72c;
        position: relative;
        height: 100%; }
    .view-highlighted-projects .project-info-box .view-project-btn,
    .view-highlighted-projects .project-info-box-medium .view-project-btn,
    .view-highlighted-projects .project-info-box-small .view-project-btn {
      padding: 20px 10px;
      position: relative;
      width: 100%;
      display: inline-block;
      text-align: center; }
      @media screen and (min-width: 768px) {
        .view-highlighted-projects .project-info-box .view-project-btn,
        .view-highlighted-projects .project-info-box-medium .view-project-btn,
        .view-highlighted-projects .project-info-box-small .view-project-btn {
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          /* Ch <36, Saf 5.1+, iOS, An =<4.4.4 */
          -ms-transform: translateY(-50%);
          /* IE 9 */
          transform: translateY(-50%);
          /* IE 10, Fx 16+, Op 12.1+ */
          padding: 25px 10px; } }
      .view-highlighted-projects .project-info-box .view-project-btn:before,
      .view-highlighted-projects .project-info-box-medium .view-project-btn:before,
      .view-highlighted-projects .project-info-box-small .view-project-btn:before {
        content: '';
        position: relative;
        background: url(../images/icon-view-project-colored.png) no-repeat;
        background-size: 40px 23px;
        width: 40px;
        height: 23px;
        display: block;
        margin: 0 auto 15px auto; }
  @media screen and (min-width: 768px) {
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects {
      margin-left: -20px;
      margin-right: -20px; } }
  @media screen and (max-width: 768px) {
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects {
      margin-left: 0px;
      margin-right: 0px; } }
  @media screen and (min-width: 769px) {
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:nth-child(4) {
      clear: both; } }
  .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row {
    margin-bottom: 25px;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
    -moz-transition: all 0.25s;
    padding-left: 20px;
    padding-right: 20px; }
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .view-project-label {
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      -moz-transition: all 0.25s;
      position: absolute;
      top: 5px;
      right: 10px;
      opacity: 1;
      font-size: 12px;
      color: #fff; }
      @media screen and (min-width: 769px) {
        .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .view-project-label {
          right: 30px;
          opacity: 0; } }
      .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .view-project-label:before {
        content: '';
        background: url(../images/icon-view-project.png) no-repeat;
        width: 25px;
        height: 14px;
        left: -30px;
        top: 3px;
        position: absolute;
        background-size: 100%; }
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-field-project-featured-image {
      background: #e7751d;
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      -moz-transition: all 0.25s; }
      @media screen and (min-width: 769px) {
        .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-field-project-featured-image {
          background: none; } }
      .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-field-project-featured-image img {
        width: 100%;
        mix-blend-mode: multiply; }
        @media screen and (max-width: 767px) {
          .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-field-project-featured-image img.opacity-mode {
            mix-blend-mode: normal;
            opacity: 0.3; } }
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-title {
      -webkit-transition: all 0.25s;
      -o-transition: all 0.25s;
      transition: all 0.25s;
      -moz-transition: all 0.25s;
      background: none;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      -webkit-transform: inherit;
      transform: inherit;
      border: none;
      color: #fff;
      font-family: "1898Sans-Bold", sans-serif;
      z-index: 10; }
      @media screen and (min-width: 769px) {
        .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-title {
          opacity: 0; } }
      @media screen and (max-width: 767px) {
        .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-title {
          font-size: 30px;
          line-height: 40px; } }
      .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-title a {
        text-decoration: none;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%; }
        .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-title a h2 {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          -o-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: auto;
          padding: 30px 30px 20px;
          font-size: 30px;
          line-height: 30px;
          border: 2px solid #fff; }
          @media screen and (max-width: 515px) {
            .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row .views-field-title a h2 {
              width: 80%; } }
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:hover .views-field-title,
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:hover .view-project-label {
      opacity: 1; }
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:hover .views-field-field-project-featured-image {
      background: #e7751d; }
      .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:hover .views-field-field-project-featured-image img.opacity-mode {
        opacity: 0.3; }
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:hover:after {
      opacity: 0; }
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:after {
      content: '';
      position: absolute;
      bottom: 5px;
      right: 25px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 25px 25px;
      border-color: transparent transparent #fff transparent;
      -webkit-animation: trianglepulse 1s ease-out infinite alternate;
      /* Safari 4+ */
      -moz-animation: trianglepulse 1s ease-out infinite alternate;
      /* Fx 5+ */
      -o-animation: trianglepulse 1s ease-out infinite alternate;
      /* Opera 12+ */
      animation: trianglepulse 1s ease-out infinite alternate;
      /* IE 10+, Fx 29+ */ }
      @media screen and (max-width: 768px) {
        .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:after {
          opacity: 0; } }
    @media screen and (max-width: 767px) {
      .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row {
        width: auto;
        float: none;
        padding: inherit !important; } }
    @media screen and (min-width: 768px) {
      .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:first-child {
        width: 66.6666666667%; } }
    @media screen and (max-width: 768px) {
      .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:first-child {
        width: 50%; } }
    @media screen and (max-width: 767px) {
      .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:first-child {
        width: auto; } }
    .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:first-child a h2 {
      font-size: 30px;
      line-height: 30px; }
      @media screen and (min-width: 769px) {
        .pane-highlighted-projects-panel-pane-2 .view-highlighted-projects .views-row:first-child a h2 {
          font-size: 50px;
          line-height: 50px; } }

.wcm-callout {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px; }
  .wcm-callout .wcm-callout-title {
    padding: 30px 30px 20px;
    font-size: 50px;
    line-height: 50px;
    border: 2px solid #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: "1898Sans-Bold", sans-serif;
    text-align: center;
    z-index: 10; }
    @media screen and (max-width: 767px) {
      .wcm-callout .wcm-callout-title {
        font-size: 30px;
        line-height: 40px; } }
  .wcm-callout img {
    mix-blend-mode: multiply;
    margin-bottom: 0 !important; }
    .wcm-callout img.opacity-mode {
      opacity: 0.3; }
  .wcm-callout.wcm-red {
    background: #b31b1b; }
    .wcm-callout.wcm-red:hover {
      background: #e7751d; }
  .wcm-callout.wcm-dark-orange {
    background: #cf4520; }
    .wcm-callout.wcm-dark-orange:hover {
      background: #e7751d; }
  .wcm-callout.wcm-bright-orange {
    background: #e7751d; }
    .wcm-callout.wcm-bright-orange:hover {
      background: #ffc72c; }
  .wcm-callout.wcm-yellow {
    background: #ffc72c; }
    .wcm-callout.wcm-yellow:hover {
      background: #e7751d; }
  .wcm-callout.no-image {
    padding: 100px; }

.panel-onecol .pane-wcm-cta .wcm-cta {
  width: auto;
  margin-top: 30px; }
  .panel-onecol .pane-wcm-cta .wcm-cta .wcm-cta__tile {
    width: 25%;
    border: none;
    border-right: 1px solid #dddddd; }
    @media screen and (max-width: 767px) {
      .panel-onecol .pane-wcm-cta .wcm-cta .wcm-cta__tile {
        width: 50%; }
        .panel-onecol .pane-wcm-cta .wcm-cta .wcm-cta__tile:nth-child(even) {
          border-right: none; }
        .panel-onecol .pane-wcm-cta .wcm-cta .wcm-cta__tile:nth-child(1), .panel-onecol .pane-wcm-cta .wcm-cta .wcm-cta__tile:nth-child(2) {
          border-bottom: 1px solid #dddddd; } }
    .panel-onecol .pane-wcm-cta .wcm-cta .wcm-cta__tile:last-child {
      border-right: none; }

#btn-vendor-inquiries,
#btn-commercial-inquiries,
#btn-project-intake {
  -webkit-box-shadow: none;
  box-shadow: none; }
  #btn-vendor-inquiries.active,
  #btn-commercial-inquiries.active,
  #btn-project-intake.active {
    background: #666666;
    border-color: #666666; }

.node-webform {
  display: none;
  margin-top: 20px; }
  .node-webform h2 {
    display: none; }
  .node-webform .form-control {
    max-width: none; }
  .node-webform .select2-container {
    width: 220px !important; }

/**
 * Styles related to Photo Stories
 *
 */
.photo-story {
  position: relative; }

.paragraphs-items-field-project-photo-gallery .cycle-slideshow {
  background: none; }

.paragraphs-items-field-project-photo-gallery .thumbnail-controller {
  background: #f7f7f7;
  padding: 30px;
  overflow: hidden; }
  .paragraphs-items-field-project-photo-gallery .thumbnail-controller:before, .paragraphs-items-field-project-photo-gallery .thumbnail-controller:after {
    content: " ";
    display: table; }
  .paragraphs-items-field-project-photo-gallery .thumbnail-controller:after {
    clear: both; }

.paragraphs-items-field-project-photo-gallery #thumbnail-pager a {
  display: inline-block;
  margin: 0 15px 10px 0; }
  .paragraphs-items-field-project-photo-gallery #thumbnail-pager a:last-child {
    margin-right: 0;
    padding-right: 0; }

.project-gallery__image {
  position: relative; }

.project-gallery__caption {
  margin: 20px 0; }

.thumbnail-controller__wrap {
  float: left;
  position: relative;
  left: 50%; }

.thumbnail-controller__wrap-inner {
  position: relative;
  left: -50%; }

.thumbnail-controller__pager {
  display: block;
  float: left;
  padding: 0 10px; }
  .thumbnail-controller__pager:after {
    content: '\e802';
    color: #e7751d;
    font-size: 24px;
    vertical-align: top;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    line-height: 1.5; }

.thumbnail-controller__pager.previous:after {
  content: '\e810'; }
  @media screen and (min-width: 992px) {
    .thumbnail-controller__pager.previous:after {
      padding: 3px 10px 0 0; } }

.thumbnail-controller__pager.next:after {
  content: '\e802'; }
  @media screen and (min-width: 992px) {
    .thumbnail-controller__pager.next:after {
      padding: 3px 0 0 10px; } }

#thumbnail-pager {
  display: none; }
  @media screen and (min-width: 992px) {
    #thumbnail-pager {
      float: left;
      display: block; } }

#mobile-slide-counter {
  float: left;
  padding: 3px 20px; }
  @media screen and (min-width: 992px) {
    #mobile-slide-counter {
      display: none !important; } }

/* =Previous / Next Arrow Overlays
----------------------------------------------------------------------------------*/
.paragraphs-items-field-project-photo-gallery .cycle-prev, .paragraphs-items-field-project-photo-gallery .cycle-next {
  display: none; }
  @media screen and (min-width: 768px) {
    .paragraphs-items-field-project-photo-gallery .cycle-prev, .paragraphs-items-field-project-photo-gallery .cycle-next {
      position: absolute;
      top: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      width: 10%;
      z-index: 1002;
      height: 100%;
      cursor: pointer; } }
  @media screen and (min-width: 992px) {
    .paragraphs-items-field-project-photo-gallery .cycle-prev, .paragraphs-items-field-project-photo-gallery .cycle-next {
      display: block; } }

.paragraphs-items-field-project-photo-gallery .cycle-prev {
  left: 0;
  background: url("../images/carousel-prev.png") 35% 50% no-repeat; }
  @media screen and (min-width: 1200px) {
    .paragraphs-items-field-project-photo-gallery .cycle-prev {
      background-position: 35% 50%; } }

.paragraphs-items-field-project-photo-gallery .cycle-next {
  right: 0;
  background: url("../images/carousel-next.png") 65% 50% no-repeat; }
  @media screen and (min-width: 1200px) {
    .paragraphs-items-field-project-photo-gallery .cycle-next {
      background-position: 35% 50%; } }

.paragraphs-items-field-project-photo-gallery .cycle-prev:hover,
.paragraphs-items-field-project-photo-gallery .cycle-next:hover {
  opacity: .7;
  filter: alpha(opacity=70); }
