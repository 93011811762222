/**
 * Styles related to Photo Stories
 *
 */

.photo-story {
  position: relative;
}

.paragraphs-items-field-project-photo-gallery .cycle-slideshow {
  background: none;
}

.paragraphs-items-field-project-photo-gallery .thumbnail-controller {
  background: #f7f7f7;
  padding: 30px;
  overflow: hidden;
  @include clearfix();
}

.paragraphs-items-field-project-photo-gallery #thumbnail-pager a {
  display: inline-block;
  margin: 0 15px 10px 0;

  &:last-child {
    margin-right: 0;
    padding-right: 0;
  }
}

.project-gallery__image {
  position: relative;
}

.project-gallery__caption {
  margin: 20px 0;
}

.thumbnail-controller__wrap {
  float: left;
  position: relative;
  left: 50%;
}

.thumbnail-controller__wrap-inner {
  position: relative;
  left: -50%;
}

.thumbnail-controller__pager {
  display: block;
  float: left;
  padding: 0 10px;
  &:after {
    content: '\e802';
    color: $wcm-bright-orange;
    font-size: 24px;
    vertical-align: top;
    @include fontello();
    text-decoration: none;
    line-height: 1.5;
  }
}

.thumbnail-controller__pager.previous {
  &:after {
    content: '\e810';
    @include breakpoint($md) {
      padding: 3px 10px 0 0;
    }
  }
}

.thumbnail-controller__pager.next {
  &:after {
    content: '\e802';
    @include breakpoint($md) {
      padding: 3px 0 0 10px;
    }
  }
}

#thumbnail-pager {
  display: none;
  @include breakpoint($md) {
    float: left;
    display: block;
  }
}

#mobile-slide-counter {
  float: left;
  padding: 3px 20px;
  @include breakpoint($md) {
    display: none !important;
  }
}

/* =Previous / Next Arrow Overlays
----------------------------------------------------------------------------------*/

.paragraphs-items-field-project-photo-gallery {
  .cycle-prev, .cycle-next {
    display: none;
    @include breakpoint($sm) {
      position: absolute;
      top: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      width: 10%;
      z-index: 1002;
      height: 100%;
      cursor: pointer;
    }
    @include breakpoint($md) {
      display: block;
    }
  }

  .cycle-prev {
    left: 0;
    background: url("../images/carousel-prev.png") 35% 50% no-repeat;
    @include breakpoint($lg) {
      background-position: 35% 50%;
    }
  }

  .cycle-next {
    right: 0;
    background: url("../images/carousel-next.png") 65% 50% no-repeat;
    @include breakpoint($lg) {
      background-position: 35% 50%;
    }
  }

  .cycle-prev:hover,
  .cycle-next:hover {
    opacity: .7;
    filter: alpha(opacity=70);
  }
}


